
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { AreasObject } from '@/store/modules/assets/AreasModule';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'shipment-size-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    methods: {
      usePlace(place) {
        if (place) {
          this.markers.pop();
          this.coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          this.center = this.coordinates;
          this.markers.push({
            id: this.markers.length + 1,
            position: this.coordinates,
          });
          this.area.latitude = place.geometry.location.lat();
          this.area.longitude = place.geometry.location.lng();
        }
      },
      updateCoordinates(location) {
        this.coordinates = {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        };
        this.area.latitude = location.latLng.lat();
        this.area.longitude = location.latLng.lng();
      },
      addMarker(location) {
        this.markers.pop();

        this.coordinates = {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        };
        this.markers.push({
          id: this.markers.length + 1,
          position: this.coordinates,
        });
        this.area.latitude = location.latLng.lat();
        this.area.longitude = location.latLng.lng();
      },
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const titleAr = () => {
        return area.value.title.find((i) => {
          if (i.languageCode === 'ar') {
            return i.title;
          }
        }).title;
      };
      const titleEn = () => {
        return area.value.title.find((i) => {
          if (i.languageCode === 'en') {
            return i.title;
          }
        }).title;
      };
      const titleKu = () => {
        return area.value.title.find((i) => {
          if (i.languageCode === 'ku') {
            return i.title;
          }
        }).title;
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('areaUpdating'), [
          translate('assets'),
        ]);
        await store.dispatch(Actions.GET_ALL_GOVERNORATES, { limit: 50 });
      });

      const { data } = await store.dispatch(Actions.GET_AREA, route.params.id);

      const area = ref<AreasObject>(data.data);

      const goBack = () => {
        router.back();
      };
      const coordinates: any = { lat: '', lng: '' };

      const markers = [
        {
          id: 1,
          position: {
            lat: 33.2209264,
            lng: 43.714387,
          },
        },
      ];

      const center = { lat: 33.2209264, lng: 43.714387 };

      if (area.value.latitude && area.value.longitude) {
        center.lat = Number(area.value.latitude);
        center.lng = Number(area.value.longitude);
        markers[0].position.lat = Number(area.value.latitude);
        markers[0].position.lng = Number(area.value.longitude);
      }
      const areaSchema = Yup.object().shape({
        governorateid: Yup.string()
          .notOneOf(['0'], () => translate('GOVERNORATE_IS_REQUIRED_FIELD'))
          .required(() => translate('GOVERNORATE_IS_REQUIRED_FIELD'))
          .label('Governorate'),
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ar'),
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('Title En'),
        titleKu: Yup.string()
          .required(() => translate('KU_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ku'),
        latitude: Yup.string()
          .required(() => translate('LATITUDE_IS_REQUIRED_FIELD'))
          .label('Latitude'),

        longitude: Yup.string()
          .required(() => translate('LONGITUDE_IS_REQUIRED_FIELD'))
          .label('Longitude'),
      });
      const onSubmitCreate = async (values) => {
        values.title = [];
        values.description = [];
        values.title.push({ languageCode: 'en', title: values.titleEn });
        values.title.push({ languageCode: 'ar', title: values.titleAr });
        values.title.push({ languageCode: 'ku', title: values.titleKu });

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        if (values.isActive == true) {
          values.isActive = true;
        } else {
          values.isActive = false;
        }

        if (values.inCity == true) {
          values.inCity = true;
        } else {
          values.inCity = false;
        }
        const payload = {
          inCity: values.inCity,
          isActive: values.isActive,
          title: values.title,
          governorateId: values.governorateid,
          latitude: values.latitude,
          longitude: values.longitude,
        };
        await store.dispatch(Actions.UPDATE_AREA, {
          id: area.value?.id,
          data: payload,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_AREA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'areas-listing' });
          });
        }
      };

      return {
        governorates: computed(() => store.getters.allGovernoratesList),
        coordinates,
        center,
        markers,
        area,
        titleAr,
        titleEn,
        titleKu,
        onSubmitCreate,
        submitButton,
        translate,
        areaSchema,
        goBack,
        can,
      };
    },
  });
